@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

/* stylelint-disable font-family-no-missing-generic-family-keyword */
.qna-login {
    .qna-input {
        .app-label {
            font-size: var(--font-size-p2);
            font-weight: var(--font-weight-n);
        }

        &.is-masked {
            .MuiInputBase-input {
                font-family: text-security-disc;
                -webkit-text-security: disc;
            }
        }
    }
}
