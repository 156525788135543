@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.center-action, .left-action, .right-action{
    z-index: 1000;
    position: absolute;
    top: var(--space);


    .app-icon-button {
        padding: var(--space-2);
    }
}

.left-action {
    left: var(--space);
}

.right-action {
    right: var(--space);
}

.center-action {
    left: 50%;
    transform: translateX(-50%);
}



