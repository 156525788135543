@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.graphical-signature-container {
    position: relative;

    .signature-line {
        position: absolute;
        width: 90%;
        height: 1px;
        margin: 0 5%;
        background-color: #000;
    }

    &.disabled-to-draw {
        .signature-line {
            display: none;
        }
    }
}
