@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.toggle-signer-interface-container {
    position: relative;
    width: 100%;
    top: calc(var(--space-2) * -1);
    align-self: flex-end;
    transition: all 250ms linear;
    margin-bottom: var(--space-2);

    .toggle-signer-interface {
        position: absolute;
        left: 0;
        border-radius: 0 0 13px 13px;
        color: var(--brand-color);
        background-color: var(--background-color-light);
        box-shadow: 0 1px 2px var(--box-shadow-color);
        padding: calc(var(--space) / 2) var(--space);

        &:hover {
            background-color: var(--background-color-light);
        }
    }

    .toggle-icon {
        margin-right: calc(var(--space) / 2);
    }

    .toggle-label {
        text-transform: uppercase;
        font-size: var(--font-size-p5);
    }

    &.is-interface-hidden {
        top: calc(var(--space) * -1);
        margin-bottom: var(--space-3);
    }

    @media (min-width: @screen-xs) {
        &.is-interface-hidden {
            top: calc(var(--space-2) * -1);
        }
    }

    @media (min-width: @screen-md) {
        display: none;
    }
}
