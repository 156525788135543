@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.loading {
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--loading-background-color);
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 99999;

    &.page-sceleton-loading {
        background-color: var(--background-color-light);
        overflow: hidden;

        .page-sceleton::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%);
            background-image:
                linear-gradient(
                    90deg,
                    var(--background-color-light) 0%,
                    var(--brand-color) 80%,
                    var(--background-color-light) 100%
                );
            animation: shimmer 1s infinite;
            opacity: 0.1;
            content: '';
        }

        @keyframes shimmer {
            100% {
                transform: translateX(100%);
            }
        }
    }
}
