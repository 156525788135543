@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.password-reset {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    .container {
        position: relative;

        .loading {
            position: absolute;
        }
    }

    .password-recovery-link-container {
        font-size: var(--font-size-p2);

        .password-recovery-link {
            font-size: var(--font-size-p2);
            font-weight: var(--font-weight-b2);
        }
    }

    .password-recovery-btn {
        margin-top: var(--space-3);
        width: calc(50% - var(--space-2));
        border-radius: 8px;
        background-color: var(--button-background-color);
        font-size: var(--font-size-h4);
        font-weight: var(--font-weight-b2);
        text-transform: none;

        &:hover,
        &:focus {
            background-color: var(--button-background-color-hover);
        }

        &.Mui-disabled {
            color: var(--button-color-disabled);
            background-color: var(--button-background-color-disabled);
        }
    }

    .info-to-recover,
    .info-receive-email {
        font-size: var(--font-size-p2);
        text-align: left;
        margin-bottom: var(--space-3);
    }

    .info-temail-sent {
        font-size: var(--font-size-p2);
        text-align: left;
    }

    .account-change-password .change-password-row {
        border-bottom: 0 none;
    }
}
