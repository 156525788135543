@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.retry-container {
    width: 100%;
    background: #fff;
    padding: var(--space-2);
    height: calc(100vh - 320px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transition: all 0.25s ease-in-out;
    cursor: pointer;

    .title { 
        text-align: center;
        font-size: var(--font-size-h4);
        color: #000;
        margin: 0;
        padding: 0;
        margin-top: var(--space-4);
    }

    .sub-title {
        color: var(--placeholder-color);
        white-space: pre-line;
        text-align: center;
    }

    .app-button {
        .icon {
            margin-right: var(--space);
        }
    }
    & > .icon {
        width: 100%;
        height: 80px;
        color: var(--danger-color);
    }
}