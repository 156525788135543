@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.animated-signature-container{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;

    .animated-signature-svg {
        width: 100%;
        height: 100%;
        touch-action: none;
    }

    .buttons-container {
        z-index: 99999999;
        bottom: 0px;
        position: absolute;
    }
}
