@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.login-sso-form {
    --sso-icon-width: 60px;

    .sso-title-container {
        display: flex;
        margin-bottom: var(--space-2);
        position: relative;

        .sso-divider {
            border-top: 1px solid var(--border-color);
            width: 100%;
            position: absolute;
            top: 50%;
        }

        .sso-title {
            visibility: hidden;
            margin: 0 auto;
            padding: 4px;
            background-color: var(--background-color-accented);
            text-transform: uppercase;
            z-index: 1;
            color: var(--label-color);
            font-size: var(--font-size-p3);
            font-weight: var(--font-weight-b1);
            border-radius: 8px;

            &.is-visible {
                visibility: visible;
            }
        }
    }

    .sso-buttons-container {
        display: flex;
        flex-direction: column;

        .sso-login-btn {
            margin-bottom: calc(var(--space) * 1.5);
            border-radius: 8px;
            color: var(--brand-color);
            border-color: var(--brand-color);

            .sso-icon {
                height: 40px;
                max-width: var(--sso-icon-width);
                margin: 0 auto;
            }

            .sso-name {
                width: calc(100% - var(--sso-icon-width) * 2 - var(--space));
                margin-right: var(--sso-icon-width);
                text-align: center;
                font-size: var(--font-size-p3);
            }

            .MuiButton-startIcon {
                width: var(--sso-icon-width);
            }
        }
    }
}
