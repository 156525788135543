@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;


.signing-field-container {
    .corner-widget {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 26px;
        height: 26px;
        top: -13px;
        right: -13px;
        z-index: 1000;

        .outlined-icon-button {
            padding: 1px;
        }

        .outlined-icon-button,
        svg {
            width: 100%;
            height: 100%;
        }

        &.loader-widget {
            display: none;

            &.active {
                display: flex;
                visibility: visible;
            }
        }
    }

    &.accepted-signature {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border: 2px solid var(--success-color);
        outline: none;
        text-align: left;
        background-color: var(--signing-field-bg-opacity);
        color: var(--signer-is-completed-color);
        text-wrap: nowrap;
        line-height: normal;

        .label {
            font-size: inherit;
            font-family: inherit;
        }

        .name {
            white-space: nowrap;
            display: inline-block;
            font-size: inherit;
            font-weight: var(--font-weight-b2);
            font-family: inherit;
        }

        .signature-image {
            display: flex;
            margin: 0 auto;
            pointer-events: none;
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }

        .field-wrapper-span {
            overflow: hidden;
            text-overflow: ellipsis;
            flex-wrap: wrap;
        }

        .undo-widget svg {
            margin-top: -15%;
            min-width: 8px;
        }

        .signing-condition-icon {
            top: 90%;
        }
    }

    &.signature-field {
        .signature-button {
            padding: 0 8px;
            width: 100%;
            height: 100%;
            background-color: var(--signing-field-signature-bg);
            color: var(--background-color);
            font-size: inherit;
            min-width: auto;
            line-height: 1.2em;
            opacity: 0.9;

            &.Mui-disabled {
                background-color: var(--signing-field-disabled-bg);
                color: var(--button-color);
            }
        }

        .text-container {
            .sub-message,
            .message {
                margin: 0;
            }

            .sub-message {
                text-transform: none;
                font-style: italic;
                font-weight: var(--font-weight-l2);
            }
        }

        &.capture-signature {
            border: 1px solid transparent;

            &.is-open-signature-selection {
                border: 1px solid var(--focus-color);
            }

            .signing-condition-icon {
                top: 90%;
            }
        }

        &.optional {
            .signature-button {
                background-color: var(--signing-field-optional-signature-bg);
                color: var(--background-color);
            }
        }
    }

    @media (hover: none) {
        // targets only devices that cant hover.
        // for capture fields on mobile signature selection widget is always visible, and for the rest only on hover/click
        .corner-widget {
            visibility: visible;
        }

        &:not(.capture-signature) {
            .corner-widget {
                visibility: hidden;
            }

            &:hover {
                .corner-widget {
                    visibility: visible;
                }
            }
        }
    }

    @media (hover: hover) {
        // targets only devices that can hover *
        .corner-widget {
            visibility: hidden;
        }

        &:hover {
            .corner-widget {
                visibility: visible;
            }
        }

        &.signature-field:not(.animated-signature-wrapper) {
            &:hover {
                opacity: 1;
                background-color: var(--brand-color);
            }

            &.capture-signature {
                .corner-widget {
                    .outlined-icon-button {
                        padding: 3px;
                    }
                }

                &:hover {
                    .corner-widget {
                        visibility: visible;
                    }
                }
            }
        }
    }

    @media (min-width: @screen-md) {
        &.capture-signature {
            .corner-widget .outlined-icon-button {
                padding: 3px;
            }
        }
    }
}
