@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.account-change-password {
    .body {
        margin-top: var(--space-2);
    }

    .password-changed-alert,
    .password-change-failed-alert {
        margin: var(--space-2) 0;
    }

    .change-password-row {
        display: flex;
        flex-wrap: wrap;
        border-bottom: solid 1px var(--border-color);
        padding-bottom: var(--space);
    }

    .input-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        width: 100%;

        &.new-password {
            margin: var(--space) 0 var(--space-2);
        }

        .label {
            color: var(--brand-color);
            font-size: var(--font-size-p2);
            font-weight: var(--font-weight-n);
        }
    }

    .password-strangth-container {
        width: 100%;
        padding: var(--space);

        .password-strangth-label {
            color: var(--label-color);
            font-size: var(--font-size-p5);
            font-weight: var(--font-weight-b2);
            text-transform: uppercase;
        }
    }

    .password-strangth-sub-checks-container {
        padding-left: var(--space-2);
    }

    .password-strangth-checks {
        .password-strangth-check,
        .password-strangth-sub-check {
            padding-top: 2px;
            display: flex;

            .icon {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-right: var(--space);

                .sub-check-icon {
                    color: var(--brand-color);
                }

                .check-icon {
                    width: 20px;
                    height: 20px;
                }
            }

            .label {
                color: var(--brand-color);
                font-size: var(--font-size-p2);
                font-weight: var(--font-weight-n);
            }
        }

        .password-strangth-check {
            .icon {
                .success {
                    color: var(--success-color);
                }

                .failed {
                    color: var(--danger-color);
                }
            }

            .label {
                font-size: var(--font-size-p1);
            }
        }
    }

    .button-container {
        margin-top: var(--space-3);

        .save-btn {
            border-radius: 2px;
            background-color: var(--button-background-color);
            color: var(--button-color);
            font-size: var(--font-size-h4);
            font-weight: var(--font-weight-n);
            padding: calc(var(--space) / 2) var(--space-5);
            width: 100%;

            &:hover,
            &:focus {
                background-color: var(--button-background-color-hover);
            }

            &.Mui-disabled {
                color: var(--button-color-disabled);
                background-color: var(--button-background-color-disabled);
            }
        }
    }

    &.reset-password-page {
        .account-title {
            display: none;
        }

        .change-password-row {
            .new-password {
                order: 1;
            }

            .password-strangth-container {
                order: 2;
            }

            .confirm-password {
                order: 3;
                margin-top: var(--space-2);
            }
        }
    }

    @media (min-width: @screen-sm) {
        .body {
            margin-top: var(--space-3);
        }

        .button-container .save-btn {
            width: auto;
        }

        .input-container {
            width: calc(50% - var(--space-2));

            &.new-password {
                margin: 0 var(--space-4) 0 0;
            }
        }
    }
}
