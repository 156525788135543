@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.language-selector {
    width: var(--space-6);

    svg {
        width: 24px;
        height: 24px;
        color: var(--brand-color);
    }

    .selector-btn-container {
        display: inline-flex;
        width: auto;
        padding: 0;
        background-color: inherit;
        border: none;
        align-items: center;
        cursor: pointer;
        outline: none;

        &:active,
        &:focus {
            border: none;
            background-color: inherit;
            outline: none;
        }

        .selector-title {
            color: var(--brand-color);
            font-weight: var(--font-weight-b2);
            font-size: var(--font-size-p1);
        }

        .selector-chevron-container {
            margin-left: var(--space);
            height: 16px;

            .selector-chevron {
                width: 16px;
                height: 16px;
                color: var(--brand-color);
            }
        }
    }
}

.language-menu-list {
    .MuiListItem-root {
        padding: 11px var(--space-2);
        color: var(--brand-color-inactive);
        font-size: var(--font-size-p1);
        font-weight: var(--font-weight-n);

        &.Mui-selected {
            color: var(--brand-color);
            font-weight: var(--font-weight-b2);
        }
    }
}
