@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.signer-ui-upload-attachment {
    margin: 0 auto;
    max-width: 800px;
    width: 100%;

    .upload-attachment-section {
        background-color: var(--page-background-color);
        box-shadow: 0 1px 2px var(--box-shadow-color);
        margin-bottom: var(--space-4);

        .title {
            padding: var(--space-2) var(--space-3);
            border-bottom: 2px solid var(--border-color);
            display: flex;
            color: var(--brand-color);
            font-size: var(--font-size-h4);
            font-weight: var(--font-weight-b3);

            .optional-label {
                margin-left: auto;
            }

            strong {
                font-weight: var(--font-weight-b3);
            }

            .icon {
                color: var(--brand-color);
                margin-right: var(--space-2);
                width: 24px;
                height: 24px;

                &.rejected-icon {
                    color: var(--declined-color);
                }
            }
        }

        .title-message {
            padding: var(--space-2) var(--space-3);
            color: var(--brand-color);
            font-size: var(--font-size-p2);
            font-weight: var(--font-weight-n);

            p {
                &:not(:first-of-type) {
                    margin-top: var(--space);
                }
            }

            strong {
                font-weight: var(--font-weight-b3);
            }
        }

        &.rejected-section {
            .title {
                border-bottom: none;
            }

            .title-message {
                border-top: 2px solid var(--border-color);
            }
        }
    }

    .upload-attachment-files {
        padding: 0;
    }
}
