@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.news-step {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: calc(100vh - 32px);
    text-align: center;

    .background-container {
        display: flex;
        flex-grow: 1;
        background-size: cover;
        height: 100%;
        position: relative;

        .image {
            position: absolute;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
            bottom: 0;
            max-height: 80%;
            max-width: 80%;
        }
    }

    .step-divider {
        margin: var(--space-3) auto var(--space);
        height: 2px;
        width: 100%;
        min-width: 350px;
        max-width: 30%;
        background-color: var(--brand-color-accented);
    }

    .step-title {
        padding: var(--space-2) var(--space-5);
        color: var(--brand-color);
        font-size: var(--font-size-h2);
        font-weight: var(--font-weight-b2);
        line-height: var(--font-size-h2);
    }

    .step-description {
        padding: 0 var(--space-5) var(--space-2);
        color: var(--brand-color);
        font-size: var(--font-size-p2);
        font-weight: var(--font-weight-l2);
        line-height: var(--font-size-p2);
    }
}
