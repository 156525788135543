@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.password-recovery {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;

    .title {
        font-size: 28px;
        font-weight: var(--font-weight-n);
        margin-bottom: var(--space-3);
        color: var(--brand-color);
    }

    .password-recovery-btn {
        margin-top: var(--space-3);
        width: calc(50% - var(--space-2));
        border-radius: 8px;
        background-color: var(--button-background-color);
        font-size: var(--font-size-h4);
        font-weight: var(--font-weight-b2);
        text-transform: none;

        &:hover,
        &:focus {
            background-color: var(--button-background-color-hover);
        }

        &.Mui-disabled {
            color: var(--button-color-disabled);
            background-color: var(--button-background-color-disabled);
        }
    }

    .info-to-recover,
    .info-receive-email {
        font-size: var(--font-size-p2);
        text-align: left;
        margin-bottom: var(--space-3);
    }

    .info-temail-sent {
        font-size: var(--font-size-p2);
        text-align: left;
    }

    .back-to-login {
        margin-top: var(--space-3);
        text-align: left;
        font-size: var(--font-size-p2);

        .link {
            text-decoration: none;
            font-weight: var(--font-weight-b2);
        }
    }
}
