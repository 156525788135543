@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.in-person-signer.MuiListItem-root {
    background-color: var(--page-background-color);
    box-shadow: 0 1px 2px var(--box-shadow-color);
    margin-bottom: var(--space);
    width: 100%;
    padding: var(--space-2) var(--space-3);
    display: flex;
    transition: all 225ms linear;
    align-items: flex-start;

    &:hover {
        background-color: var(--brand-color-hover-light);
    }

    .MuiTouchRipple-child {
        background-color: var(--brand-color);
    }

    &::before {
        top: 0;
        left: 0;
        width: 4px;
        bottom: 0;
        content: "";
        display: block;
        opacity: 0;
        z-index: 2;
        position: absolute;
        background-color: var(--brand-color);
        transition: all 225ms linear;
    }

    .avatar-container {
        width: 78px;
        height: 78px;
        margin-right: var(--space-2);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .avatar {
        width: 70px;
        height: 70px;
        font-size: var(--font-size-h2);
        transition: all 225ms linear;
    }

    .order-badge {
        .MuiBadge-badge {
            color: var(--brand-color);
            font-size: var(--font-size-p2);
            cursor: default;
        }
    }

    .info-status-container {
        display: flex;
        flex-wrap: wrap;
        flex-grow: 1;
    }

    .info-container {
        color: var(--brand-color);
        margin-right: var(--space-2);
    }

    .name-container {
        color: var(--brand-color);
        font-weight: var(--font-weight-b3);
        font-size: var(--font-size-h4);
        margin-bottom: var(--space);
    }

    .email-container,
    .type-container {
        margin-bottom: calc(var(--space) / 4);
    }

    .action-container {
        font-weight: var(--font-weight-b3);
    }

    .status-container {
        margin-top: var(--space);
        display: flex;
        align-items: center;
        color: var(--brand-color);
        flex-grow: 1;
        max-width: 180px;
        font-weight: var(--font-weight-b3);

        .in-person-status-icon {
            color: var(--pending-color);
            width: 24px;
            height: 24px;
            margin-right: var(--space);

            &.is-in-progress {
                color: var(--sent-color);
            }

            &.is-done {
                color: var(--completed-color);
            }
        }
    }

    &.Mui-selected {
        background-color: var(--page-background-color);
        width: calc(100% + var(--space-2));
        margin-left: calc(var(--space) * -1);
        margin-right: calc(var(--space) * -1);
        box-shadow: 0 2px 10px var(--box-shadow-color);

        &::before {
            opacity: 1;
        }

        .avatar {
            width: 80px;
            height: 80px;
            font-size: var(--font-size-h2);
        }
    }

    @media (min-width: @screen-sm) {
        flex-direction: row;
        align-items: center;

        .info-container {
            flex-grow: 1;
        }

        .status-container {
            margin: var(--space-2) 0 0 0;
        }
    }
}
