@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.uploaded-file-preview-container {
    .uploaded-file-preview {
        height: 350px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: var(--space-2);
        margin-top: var(--space-2);
        border-top: 2px solid var(--border-color);
        width: 100%;

        &.has-preview {
            height: 500px;
        }

        .preview-img {
            max-width: 100%;
            max-height: 100%;
            flex-shrink: 0;
        }
    }

    .no-preview-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .no-preview-icon-container {
            margin-bottom: var(--space-3);

            .no-preview-icon {
                width: 130px;
                height: 130px;
                color: var(--brand-color);
            }
        }

        .no-preview-message {
            color: var(--brand-color);
            font-size: var(--font-size-h0);
        }
    }
}
