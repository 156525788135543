@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;


.sms-verification-modal {
    .MuiPaper-root {
        width: 450px;
    }

    .passcode {
        margin-top: var(--space-2);
    }

    .resend-button {
        margin-top: var(--space-2);
        font-size: var(--font-size-p2);

        &.success,
        &.disabled {
            color: var(--success-color);
            cursor: initial;
        }
    }

    .MuiDialogActions-root .MuiButton-root.app-button {
        color: var(--button-color);
    }
}
