@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.signature-selection-modal {
    display: block;

    .MuiTypography-root {
        font-weight: var(--font-weight-b1);
        text-transform: uppercase;
    }
    .MuiDialog-paper{
        max-width: 600px;
    }

    .image-thumbnail {
        .icon {
            color: var(--brand-color);
        }
    }

    .title {
        padding-left: 0;
    }

    .content {
        padding: 0 var(--space-2);
    }

    .tabs-container {
        padding: 0;

        .MuiTabs-flexContainer {
            justify-self: center;
        }

        .MuiTab-labelIcon {
            min-height: auto;
        }

        .MuiTab-root {
            .MuiTab-wrapper {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                color: var(--brand-color);

                .icon {
                    margin: 0;
                    margin-left: var(--space);
                    color: var(--brand-color);
                    font-size: 25px;

                    &.signature-icon {
                        width: 25px;
                    }
                }
            }
        }
    }

    .tabs-content {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
        padding: 0 0 var(--space-2);
    }

    .signing-method {
        width: 100%;
        align-items: stretch;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        background-color: var(--background-color);
        padding: var(--space-2);
        transition: all 0.1s ease-in-out;

        &.error {
            .image-component {
                filter: grayscale(100%);
            }
        }

        .content {
            display: flex;
            flex-direction: column;
            align-items: center;
            height: 100%;
            padding: 0;
        }

        .footer {
            padding-top: var(--space-2);
            margin-top: auto;
            display: flex;
            justify-content: center;
        }

        .info-alert {
            margin-top: var(--space-2);
        }

        .upload-image-container {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .info-alert {
                background-color: var(--background-color-light);
            }
        }

        .image-container,
        .drawed-signature-container {
            width: 100%;
            display: flex;
            justify-content: center;

            canvas {
                background-color: #fff !important;
            }
        }

        .image-container .image-component,
        .graphical-signature-container {
            border-radius: 4px;
            border: 2px solid var(--brand-color);
            overflow: hidden;
        }

        .update-signature-button {
            margin-top: var(--space-2);
        }

        .image-thumbnail {
            width: 100px;
            height: 100px;
            cursor: pointer;
        }
    }

    .primary-button.MuiButtonBase-root.save-btn {
        color: var(--button-color);
        width: auto;
    }

    @media screen and (max-width: @screen-sm) and (orientation: landscape) {
        .content {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
        }

        .tabs-container {
            align-items: center;
        }

        .tabs-content {
            flex-grow: 1;
        }

        .signing-method {
            flex-direction: row;
            justify-content: space-around;
            align-items: center;

            .footer {
                padding: 0;
                margin: 0;
            }

            .upload-image-container,
            .drawed-signature-container {
                width: 200px;
                height: 100px;
                position: relative;

                .info-alert {
                    margin-top: 0;
                    position: absolute;
                }
            }
        }
    }
}
