@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.in-person-session-information {
    margin: 0 auto 0 0;
    border-radius: 4px;
    text-align: center;
    display: flex;
    align-items: flex-end;
    margin-right: 0;
    overflow: hidden;
    width: calc(42px + var(--space));
    padding: var(--space) 0 var(--space) var(--space);

    .avatar {
        width: 42px;
        height: 42px;
        margin-right: var(--space-2);
    }

    .label {
        color: var(--brand-color);
        font-size: var(--font-size-p2);
    }

    @media (min-width: @screen-sm) {
        padding: var(--space-2);
        margin-right: var(--space-2);
        width: auto;
        align-items: center;
    }
}

.in-person-session-information-popover {
    padding: var(--space);
}
