@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.app-input-container {
    .MuiFormHelperText-root {
        margin-left: var(--space);
    }
}
