@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.signing-ceremony {
    padding: var(--space) var(--space) 0;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 0 auto;
    background-color: var(--background-color);
    min-height: calc(100dvh - var(--header-height));
    min-width: 0;
    flex-shrink: 1;

    &.pdf {
        overflow: hidden;
    }

    @media (min-width: @screen-xs) {
        padding: var(--space-2);
    }

    @media (min-width: @screen-sm) {
        &.has-left-menu {
            width: calc(100% - var(--header-height));
        }
    }

    @media (min-width: @screen-lg) {
        padding: var(--space-2) var(--space-4) 0;
    }
}
