@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.pagination {
    display: flex;

    .MuiInputBase-root {
        height: 32px;
    }

    .MuiSvgIcon-root {
        color: var(--brand-color);
    }

    .MuiPaginationItem-root {
        color: var(--brand-color);
        font-size: var(--font-size-h4);
        font-weight: var(--font-weight-l2);

        .Mui-selected {
            color: var(--brand-color);
            font-weight: var(--font-weight-n);
        }
    }

    .base-select-container {
        width: 60px;
        min-height: 30px;
        height: 30px;
        margin: 0 var(--space-2) var(--space-2) 0;
    }
}
