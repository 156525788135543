@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.signature-selection-modal-v2 {
    display: block;

    .MuiPaper-root {
        max-width: 1000px;
    }

    .MuiTypography-root {
        font-weight: var(--font-weight-b1);
        text-transform: uppercase;
    }

    .image-thumbnail {
        width: 100px;
        height: 100px;
        cursor: pointer;
        .icon {
            color: var(--brand-color);
        }
    }

    .title {
        padding-left: 0;
        padding-top: 0;
    }

    .content {
        padding: var(--space-2);
        height: 100%;
        .content{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .panel-container{
            height: 10%;
        }
    }
    .actions {
        height: 10%;
        padding: var(--space-2);
        flex-direction: column;
        align-items: center;

        .middle-actions-container{
           margin-bottom: var(--space-3);
        }
        .main-actions-wrapper{
            margin-left: auto;
        }
    }


    .image-component{
        max-width: 350px;
        max-height: 150px;
        height: 100%;
    }


    @media (min-width: @screen-md + 100px) {
        .actions {
            .middle-actions-container{
                position: absolute;
                left: 50%;
                transform: translate(-50%, 0);
                margin-bottom: 0;
            }
            
        }
    }   

    @media screen and (max-width: @screen-sm){
      

        
    }
}

