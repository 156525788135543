@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.signing-document-view-container {
    width: 100%;
    max-width: 900px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    height: calc(100dvh - var(--header-height) - var(--space-2));
    flex-direction: column;
    align-items: center;

    .documents-tool,
    .documents-renderer-container,
    .documents-tools-collapsed-container {
        width: 100%;
    }

    .documents-tools-collapsed-container {
        flex-shrink: 0;
    }

    .documents-tool {
        margin-bottom: var(--space-2);
        flex-shrink: 0;
    }

    .documents-renderer-container {
        min-height: 0;
        overflow: hidden;

        .documents-renderer-content-wrapper {
            .scrollbar-track {
                .scrollbar-thumb {
                    background-color: var(--brand-color);
                }
            }
        }
    }

    .container-for-sticky-button {
        margin: 0 auto;
        position: relative;
    }

    #viewer {
        height: 100%;
        position: relative;
        will-change: transform;
    }

    .loading {
        position: absolute;
        z-index: 0;
    }

    @media (min-width: @screen-sm) {
        height: calc(100dvh - var(--header-height) - var(--space-4));
    }
}
