@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.underlined-button {
    text-decoration: underline;
    cursor: pointer;
    color: var(--brand-color);

    &.disabled:hover {
        text-decoration: underline;
    }

    &:hover {
        text-decoration: none;
    }
}
