@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.in-person {
    margin: 0 auto;
    max-width: 800px;
    width: 100%;

    p {
        margin: 0;
    }

    .in-person-section {
        .section-content-container {
            color: var(--brand-color);
        }
    }

    .start-signing-btn {
        display: block;
        margin: var(--space-3) 0 var(--space-4) auto;
    }
}
