@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.MuiInputBase-root.base-input-container,
.MuiOutlinedInput-root.base-select-container {
    min-height: var(--outlined-min-height);
    border-radius: 4px;
    font-size: 14px;
    padding: 0;
    color: var(--brand-color);

    fieldset {
        border: 1px solid var(--outlined-border-color);
        border-radius: 4px;
    }

    &.disabled {
        background-color: var(--outlined-bg-disabled);

        fieldset {
            border-color: var(--button-color-disabled) !important;
        }
    }

    &:hover {
        fieldset {
            border: 1px solid var(--outlined-border-color-hover);
        }
    }

    &.Mui-focused {
        fieldset {
            border: 2px solid var(--brand-color);
        }

        &:hover {
            fieldset {
                border-width: 2px;
            }
        }
    }

    input,
    textarea {
        font-style: normal;
        padding: var(--outlined-input-padding);

        &::placeholder {
            color: var(--placeholder-color);
            font-size: var(--font-size-p2);
            font-weight: var(--font-weight-l2);
        }
    }
}
