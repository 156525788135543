@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.bordered-text-label {
    border: 1px solid var(--brand-color);
    color: var(--brand-color);
    border-radius: 20px;
    padding: 0 var(--space);
    font-size: var(--font-size-p5);
    margin-left: auto;
}
