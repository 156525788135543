@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.reassign-auth-challenges-container {
    .challenge-container {
        margin-bottom: var(--space-4);
    }
}
