@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.gdpr-bunner {
    background-color: var(--background-color-light);
    z-index: 9999;
    color: var(--brand-color-secondary);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: var(--space) var(--space-2);
    box-shadow: 0 -1px 9px 20px var(--box-shadow-color);

    .MuiIconButton-root {
        color: var(--brand-color-secondary);
    }

    .message-container {
        margin-right: var(--space-2);

        span, a {
            font-size: var(--font-size-p2);
        }
    }

    .policies-link {
        width: 100%;
        margin-left: var(--space-2);
    }

    .dismiss-btn {
        width: 100%;
    }
}

.validsign-signer {
    .gdpr-bunner {
        position: fixed;
        top: 0;
    }
}

@media (max-width: @screen-sm) {
    .gdpr-bunner {
        flex-direction: column;

        .message-container {
            display: flex;
            flex-direction: column;
            margin-bottom: var(--space-2);

            span, a {
                font-size: var(--font-size-p4);
            }
        }

        .policies-link {
            margin-left: 0;
        }
    }
}

@media (min-width: @screen-sm) {
    .gdpr-bunner {

        .policies-link {
            white-space: nowrap;
            width: auto;
        }

        .dismiss-btn {
            width: auto;
        }
    }
}