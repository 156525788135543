@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.country-select-container {
    .MuiSelect-iconOutlined {
        right: 0;
    }

    .MuiSelect-select {
        width: 40px;
        min-width: auto;
        font-size: 0;
        opacity: 0;
    }

    &.disabled {
        & > svg {
            opacity: 0.8;
        }
    }
}
