@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.sender-summary-mobile-row {
    display: flex;
    flex-direction: column;
    background-color: var(--background-color-accented);
    border-radius: 10px;
    margin-bottom: var(--space-3);

    .title {
        padding: var(--space) var(--space-2);
        margin-bottom: var(--space);

        .link-container {
            display: flex;
        }

        .sender-name {
            color: var(--brand-color);
            font-size: var(--font-size-h4);
            font-weight: var(--font-weight-b2);
        }

        .icon-container {
            margin-left: auto;

            .icon {
                color: var(--brand-color);
            }
        }
    }

    .packages {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0 var(--space-2);

        .package {
            display: flex;
            margin: 0 var(--space-3) var(--space-3) 0;
            min-width: 200px;
            justify-content: space-between;

            .status {
                font-size: var(--font-size-p1);
            }

            .amount {
                font-size: var(--font-size-p1);
                font-weight: var(--font-weight-b1);
            }
        }
    }
}
