@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.documents-renderer-container {
    --scroll-bar-transition-delay: var(--scroll-bar-transition-speed);

    .document-loader {
        width: 100vw;
        height: 100vh;
    }

    position: relative;
    display: flex;
    flex-direction: column;

    .documents-renderer-content-wrapper {
        max-height: 100%;
        height: 100vh;
        position: relative;

        .scrollbar-track {
            background-color: var(--scroll-bar-track-color);
            border-radius: calc(var(--scroll-bar-width) / 2);
            cursor: pointer;
            transition:
                width var(--scroll-bar-transition-speed) ease-in-out,
                height var(--scroll-bar-transition-speed) ease-in-out,
                opacity var(--scroll-bar-transition-speed) var(--scroll-bar-transition-delay) ease-out;

            .scrollbar-thumb {
                background-color: var(--scroll-bar-thumb-color);
                transition:
                    width var(--scroll-bar-transition-speed) ease-in-out,
                    height var(--scroll-bar-transition-speed) ease-in-out;
            }

            &.scrollbar-track-x {
                height: var(--scroll-bar-width);

                .scrollbar-thumb {
                    height: var(--scroll-bar-width);
                }
            }

            &.scrollbar-track-y {
                width: var(--scroll-bar-width);

                .scrollbar-thumb {
                    width: var(--scroll-bar-width);
                }
            }

            &:active {
                .scrollbar-thumb {
                    background-color: var(--scroll-bar-thumb-active-color);
                }
            }

            &:hover,
            &:active {
                border-radius: calc(var(--scroll-bar-hovered-width) / 2);

                &.scrollbar-track-x {
                    height: var(--scroll-bar-hovered-width);

                    .scrollbar-thumb {
                        height: var(--scroll-bar-hovered-width);
                    }
                }

                &.scrollbar-track-y {
                    width: var(--scroll-bar-hovered-width);

                    .scrollbar-thumb {
                        width: var(--scroll-bar-hovered-width);
                    }
                }
            }
        }
    }

    @media (min-width: @screen-sm) {
        .documents-renderer-content-wrapper {
            padding-right: calc(var(--scroll-bar-hovered-width) + var(--space));
            margin-left: calc(var(--scroll-bar-hovered-width) + var(--space));
        }
    }
}
