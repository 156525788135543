@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;


.signing-field-container.autofill-field {
    line-height: 1;
    z-index: 0;

    &.label-style {
        color: var(--signing-field-text);
        word-wrap: break-word;
        word-break: break-word;
        font-weight: var(--font-weight-b2);
        display: flex;
        align-items: center;

        .autofill-field-content {
            color: var(--signing-field-text);
            overflow: hidden;
            height: inherit;
            display: flex;
            align-items: center;
            width: 100%;
            padding-left: var(--space);
        }
    }
}
