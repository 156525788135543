@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.sui-attachment-container {
    width: 100%;
    display: flex;
    padding: calc(calc(var(--space) + 2px)) 0;
    border-top: 1px solid var(--border-color);
    min-height: 50px;

    .status-container {
        display: flex;
        flex-shrink: 0;
        align-items: center;
        width: calc((100% / 12) * 4.5);
        border-right: 2px solid var(--border-color);
        margin-right: var(--space-5);
        font-size: var(--font-size-p3);

        .attachment-icon {
            width: 24px;
            height: 24px;
            margin-right: var(--space);
            color: var(--pending-color);
            flex-shrink: 0;

            &.is-rejected {
                color: var(--declined-color);
            }

            &.is-done {
                color: var(--completed-color);
            }
        }
    }

    .name-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        color: var(--brand-color);
        width: calc((100% / 12) * 7.5);

        .name {
            font-size: var(--font-size-p1);
            display: flex;
            align-items: center;
            width: 100%;

            .optional-label {
                margin-left: var(--space-2);
            }
        }

        .description {
            margin-top: calc(var(--space) / 2);
            font-size: var(--font-size-p2);
            width: 100%;
            color: var(--brand-color-secondary);
        }
    }

    @media (min-width: @screen-sm) {
        .status-container {
            width: calc((100% / 12) * 2.5);
        }

        .name-container {
            width: calc((100% / 12) * 9.5);
        }
    }
}
