@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.sender-summary {
    .page-title-container {
        display: flex;
        flex-wrap: wrap;

        .page-title {
            display: flex;
            align-items: center;
            width: auto;
            max-width: 100%;
            flex-wrap: wrap;

            .report-name {
                color: var(--brand-color);
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
                white-space: nowrap;
                font-weight: var(--font-weight-b1);
                font-size: var(--font-size-h2);
                line-height: calc(var(--font-size-h2) + var(--space));
                margin: 0;
            }

            .sub-title {
                width: 100%;
                color: var(--label-color);
                font-size: var(--font-size-p5);
                font-weight: var(--font-weight-b1);
                text-transform: uppercase;
                line-height: var(--font-size-p5);
            }
        }
    }

    .sender-summary-header {
        display: flex;
        margin: var(--space-3) 0 var(--space-2);

        .start-date-container,
        .end-date-container {
            display: flex;
            align-items: center;
            color: var(--label-color);
        }

        .start-date-container {
            margin-left: var(--space-5);
        }

        .end-date-container {
            margin-left: var(--space-3);
        }
    }

    .sender-summary-pagination-container {
        display: flex;
        justify-content: center;
    }

    .transactions-summary {
        & when (var(--theme) = dark) {
            background-color: var(--background-color-accented);
        }
    }
}
