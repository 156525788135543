@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.phone-input {
    position: relative;
    color: var(--brand-color);

    &.prefilled {
        background-color: var(--prefilled-background-color);
    }

    .country-select-container {
        width: 65px;
        display: flex;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;

        & > svg {
            width: 30px;
            height: 30px;
            position: absolute;
            top: 5px;
            left: 11px;
            z-index: 2;
        }

        &.disabled {
            & > svg {
                opacity: 0.7;
            }
        }

        fieldset {
            border-radius: var(--input-border-radius) 0 0 var(--input-border-radius);
            border: none !important;
        }
    }

    .base-input-container input {
        padding-left: 70px;
    }

    .base-input-container {
        width: 100%;
    }
}
