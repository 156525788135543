@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.floating-action-btn-container.clone-transaction {
    .floating-action-btn {
        z-index: 100;
    }

    .template-floating-actions-list {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;

        .template-floating-action-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            list-style-type: none;
            transition: 0.3s;
            padding: var(--space);
        }
    }

    .template-floating-action-btn {
        width: 100%;
        height: 100%;
        box-shadow: 0 2px 17px -1px rgba(0, 0, 0, 0.3);
        background-color: var(--brand-color);

        .MuiIconButton-label {
            color: var(--button-color);
            font-size: var(--font-size-p1);
            text-transform: uppercase;
        }

        &:hover,
        &:focus {
            background-color: var(--button-background-color-hover);
        }

        &.Mui-disabled {
            background-color: var(--button-background-color-disabled);

            .icon {
                color: var(--icon-button-color-disabled);
            }
        }
    }
}
