@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.page-renderer {
    position: relative;
    margin: 0 auto;
    overflow: hidden;

    .page-renderer-wrapper {
        transform-origin: top left;
    }

    .annotationLayer {
        .linkAnnotation > a:hover {
            background-color: var(--brand-color-hover);
            box-shadow: none;
        }
    }
}
