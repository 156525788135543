@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.transaction-summary {
    padding: var(--space);
    background-color: var(--background-color-accented);
    border-radius: 10px;
    margin-bottom: var(--space-2);

    & when (var(--theme) = dark) {
        background-color: var(--background-color-light);
    }

    .transaction-summary-header {
        padding: 0 0 0 var(--space-2);
        cursor: pointer;

        .MuiListItemText-root {
            margin: 0;
        }

        .MuiTypography-root {
            color: var(--brand-color);
            font-size: var(--font-size-h3);
            font-weight: var(--font-weight-b2);
        }
    }

    .chevron-icon {
        color: var(--brand-color);
    }

    .linear-progress {
        margin: var(--space-4) var(--space-2);
    }
}
