@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.thank-you-host-page {
    margin: 0 auto;
    width: 100%;
    max-width: 800px;

    .section-title {
        .sub-title {
            margin-top: var(--space-2);
            font-size: 14px;
        }
    }

    .download-all-button {
        .icon {
            font-size: var(--font-size-h4);
            margin-right: var(--space);
        }
    }

    .MuiListItem-root.Mui-disabled {
        opacity: 1;
    }

    .in-person-signers {
        margin: var(--space-3) 0;
    }

    .more-actions {
        display: flex;
        justify-content: flex-end;

        .app-button {
            margin-left: var(--space-3);
        }
    }

    .more-actions-section .section-content-container {
        display: flex;
        justify-content: flex-end;
    }
}
