@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.MuiDialog-root.next-to-sign-modal-container {
    .next-transaction-info {
        .title {
            margin-top: 0;
        }

        .info-item {
            color: var(--brand-color);
            margin: var(--space) 0;
        }
    }

    .MuiDialogActions-root .primary-button {
        width: auto;
        margin-left: auto;
        color: var(--button-color);
        font-weight: var(--font-weight-n);
    }

    @media (min-width: @screen-sm) {
        .MuiDialog-paper {
            min-width: 400px;
        }
    }
}
