@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.animated-signature-wrapper {
    border: 2px solid transparent;
    border-radius: 4px;
    position: absolute;
    background-color: var(--signing-field-bg-opacity);
}
