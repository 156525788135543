@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.confirmation-modal {
    .MuiDialogTitle-root {
        padding: var(--space-2) var(--space-3) 0;
    }

    .MuiDialogContent-root {
        padding: var(--space) var(--space-3) 0;
    }

    .description-msg {
        min-width: 200px;
    }

    @media (min-width: @screen-sm) {
        .description-msg {
            min-width: 200px;
        }
    }
}
