@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.dialog {
    color: var(--brand-color);

    p {
        margin: 0;
    }

    .dialog-button {
        color: var(--brand-color);
    }

    .title {
        color: var(--brand-color);
        padding: var(--space-2) var(--space-2);
        margin: 0;
    }

    .content {
        color: var(--brand-color);
        padding: var(--space) var(--space-2);
    }

    .actions {
        padding: var(--space) var(--space-2);
        display: flex;
        justify-content: flex-end;

        button:last-child {
            margin-left: var(--space-2);
        }
    }

    .MuiDialog-paper {
        width: 100%;
    }

    &.dialog-full-width {
        .MuiDialog-paper {
            max-width: 100vw;
        }
    }

    @media (max-width: @screen-xs) {
        .MuiDialog-paper {
            margin: var(--space);
        }

        // WARNING: Part .MuiButtonBase-root is an workaround needs to overwrite global styles
        // .signer-ui-body .MuiButton-root | if you have an ideas how to fix it let me know
        .dialog-button.MuiButtonBase-root,
        .app-button.MuiButtonBase-root {
            font-size: var(--font-size-p3);
        }
    }
}
