@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.app-button.MuiButtonBase-root {
    width: auto;
    height: 40px;
    box-shadow: 0 2px 4px var(--box-shadow-color);
    padding: var(--space) var(--space-2);
    font-size: var(--font-size-p1);
    font-weight: var(--font-weight-n);
    color: var(--background-color);
    background-color: var(--button-background-color);
    border-radius: var(--button-border-radius);

    &:hover,
    &:focus {
        background-color: var(--button-background-color-hover);
    }

    &.disabled:disabled {
        color: var(--button-color-disabled);
        background-color: var(--button-background-color-disabled);
    }

    @media only screen and (max-width: 600px) {
        white-space: nowrap;
    }
}
