@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.sui-document-container {
    width: 100%;
    display: flex;
    align-items: center;
    padding: calc(calc(var(--space) + 2px)) 0;
    border-top: 1px solid var(--border-color);
    min-height: 50px;

    .status-container {
        display: flex;
        flex-shrink: 0;
        padding-right: var(--space);
        align-items: center;
        width: calc((100% / 12) * 3.5);
        border-right: 2px solid var(--border-color);
        margin-right: var(--space-2);
        font-size: var(--font-size-p3);

        .status {
            color: var(--brand-color);
        }

        .document-icon {
            width: 24px;
            height: 24px;
            margin-right: var(--space);
            color: var(--pending-color);
            flex-shrink: 0;

            &.is-in-progress {
                color: var(--sent-color);
            }

            &.is-done {
                color: var(--completed-color);
            }
        }
    }

    .name-container {
        display: flex;
        align-items: center;
        font-size: var(--font-size-p1);
        color: var(--brand-color);
        width: calc((100% / 12) * 7.5);
    }

    @media (min-width: @screen-xs) {
        .status-container {
            width: calc((100% / 12) * 3.5);
            margin-right: var(--space-3);
        }
    }

    @media (min-width: @screen-sm) {
        .status-container {
            width: calc((100% / 12) * 3);
            margin-right: var(--space-5);
        }

        .name-container {
            width: calc((100% / 12) * 9.5);
        }
    }
}
