@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.senders-summary {
    .mobile-list {
        display: flex;
        flex-direction: column;
    }

    .senders-summary-table {
        display: none;
    }

    .summary-empty-row {
        color: var(--brand-color);
        font-size: var(--font-size-p1);
        padding: 0 var(--space) var(--space-3);
        text-align: center;
        width: 100%;
    }

    @media (min-width: @screen-md) {
        .mobile-list {
            display: none;
        }

        .senders-summary-table {
            display: table;
        }
    }
}
