@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.MuiInputBase-root.base-input-container {
    display: flex;

    .MuiInputAdornment-root {
        margin: 0 8px;

        &.MuiInputAdornment-positionStart {
            margin: 0 auto 0 8px;
        }

        &.MuiInputAdornment-positionEnd {
            margin: 0 8px 0 auto;
        }
    }
}
