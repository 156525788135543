@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.decline-signing {
    margin: 0 auto;
    max-width: 800px;
    width: 100%;

    p {
        margin: 0;
    }

    strong {
        font-weight: var(--font-weight-b3);
    }

    .decline-section {
        background-color: var(--page-background-color);
        box-shadow: 0 1px 2px var(--box-shadow-color);
        margin-bottom: var(--space-4);

        .title {
            padding: var(--space-2) var(--space-3);
            border-bottom: 2px solid var(--border-color);
            display: flex;
            color: var(--brand-color);
            font-size: var(--font-size-h4);
            font-weight: var(--font-weight-b3);
        }

        .title-message {
            padding: var(--space-2) var(--space-3);
            color: var(--brand-color);
            font-size: var(--font-size-p2);
            font-weight: var(--font-weight-n);
        }
    }

    .actions-container {
        display: flex;
        margin-bottom: var(--space-4);

        .decline-signing-btn {
            margin-left: auto;
            background-color: var(--brand-color);
            color: var(--button-color);
            padding: var(--space) var(--space-4);

            &:hover,
            &:focus {
                background-color: var(--brand-color);
            }

            &.Mui-disabled {
                color: var(--button-color-disabled);
                background-color: var(--button-background-color-disabled);
            }
        }
    }
}
