@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.personal-certificate-dialog {
    position: relative;

    .certificate-download-button {
        margin-top: var(--space-2);
    }

    .certificate-signing-list-item {
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 0.5rem;
        background-color: var(--item-background-color);
    }

    .certificate-details-value {
        font-weight: var(--font-weight-b2);
    }

    .personal-certificate-error-message {
        margin: 0.5rem 2rem 2rem 2rem !important;
        padding: 0.5rem;
        border-radius: 0.25rem;
        background-color: var(--error-background);
        color: var(--danger-color);
    }

    .certificate-signing-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: var(--space) var(--space-2) 0 var(--space-2);
        position: relative;
        z-index: 20;

        h2 {
            padding: 0 0 0 var(--space-2);
            margin: 0;
        }

        .certificate-details-header-left {
            display: flex;
            align-items: center;
        }
    }

    .certificate-signing-macos-message {
        background-color: rgb(255, 236, 210);
        color: var(--brand-color-accented);
        padding: var(--space-2);
    }
}
