@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.signing-field-container {
    --field-left-padding: ~"max(8px, 5%)";

    &.form-field {
        background-color: var(--signing-field-input-bg);
        border: 1px solid var(--signing-field-color);
        color: var(--signing-field-text);
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: inherit;

        .MuiInputBase-root {
            width: 100%;
            height: 100%;
            font-size: inherit;
        }

        &.disabled {
            opacity: var(--signing-field-disabled-opacity);
            background-color: var(--signing-field-disabled-bg-light);
        }

        &:not(.disabled):hover {
            color: var(--signing-field-text);
        }

        &.required {
            .required-icon {
                position: absolute;
                background-color: var(--background-color-light);
                border-radius: 50%;
                color: var(--danger-color);
            }
        }

        &.error {
            border: 1px solid var(--danger-color);

            &:not(.disabled):hover {
                background-color: var(--background-color-light);
                box-shadow: var(--signing-field-error-box-shadow);
            }
        }

        &.input,
        &.textarea {
            .MuiInputBase-root {
                color: var(--signing-field-color);
                padding: 0 var(--field-left-padding);
                width: 100%;
                height: inherit;
                font-size: inherit;

                .MuiInputBase-input {
                    overflow: auto !important;
                    padding: 0;
                    color: var(--signing-field-color);
                    font-size: inherit;
                    height: 100%;

                    &::placeholder {
                        color: var(--placeholder-color);
                    }
                }
            }
        }

        &.textarea .MuiInputBase-root {
            padding: 0;

            .MuiInputBase-input {
                padding: 0 var(--field-left-padding);
                max-height: 100%;
            }
        }

        // this a workaround which needs to eliminate MUI icon which is smaller than checkbox and radio dimensions
        &.checkbox {
            .field-wrapper-span path {
                transform: translate(-20%, -20%) scale(1.4);
            }
        }
  
        &.radio {
            border-radius: 50%;

            .field-wrapper-span path {
                transform: translate(-10%, -10%) scale(1.2);
            }

            .MuiButtonBase-root {
                padding: 0;
            }
        }

        &.checkbox,
        &.radio {
            border: 1px solid transparent; // these fields doesn't need border because they use svg to create border. border needs to avoid jumping on hover

            * {
                width: 100%;
                height: 100%;
                padding: 0;
            }

            .field-wrapper-span {
                width: auto;
                overflow: visible;
            }

        }

        &.date {
            .MuiFormControl-root {
                width: 100%;
                height: 100%;
            }

            .MuiInputBase-root {
                padding: 0 0 0 var(--field-left-padding);

                input {
                    font-size: inherit;
                }
            }

            .MuiIconButton-root {
                padding: 6px;
                font-size: small;

                * {
                    font-size: inherit;
                }
            }

            @media (min-width: @screen-xs) {
                &.has-conditions {
                    .MuiInputBase-root {
                        padding: 0 var(--space) 0 var(--field-left-padding);
                    }
                }
            }

            @media (min-width: @screen-sm) {
                .MuiIconButton-root {
                    font-size: medium;
                }
            }

            @media (min-width: @screen-md) {
                .MuiInputBase-root {
                    padding: 0 var(--field-left-padding);
                }
            }
        }

        &.list {
            .base-select-container {
                font-size: inherit;
                min-height: auto;

                .base-select {
                    height: 100%;
                    padding: 0 0 0 var(--space);
                    font-size: inherit;
                    text-align: left;
                    display: flex;
                    align-items: center;
                }

                input {
                    padding: 0 0 0 var(--field-left-padding);
                }

                fieldset {
                    border: none;
                }

                .MuiSvgIcon-root {
                    display: block;
                    max-width: 15%;
                }

                @media (min-width: @screen-sm) {
                    .MuiSvgIcon-root {
                        display: block;
                        max-width: auto;
                    }
                }
            }
        }
    }
}

.field-list-menu {
    li {
        font-size: var(--font-size-p3);
        font-family: "Montserrat", sans-serif;
    }
}
