@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.signing-fields-container {
    top: 0;
    position: absolute;
    will-change: auto;
    backface-visibility: hidden;
}
