@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.signing-welcome-screen {
    margin: 0 auto;
    max-width: 800px;
    width: 100%;

    .section-with-title.documents-container {
        .section-title-container {
            .icon {
                fill: var(--brand-color);
            }
        }
    }

    p {
        margin: 0;
    }

    .title-message,
    .documents-message,
    .uploads-message,
    .email-message {
        color: var(--brand-color);

        p {
            &:not(:first-of-type) {
                margin-top: var(--space);
            }
        }

        strong {
            font-weight: var(--font-weight-b3);
        }
    }

    .actions-container {
        display: flex;
        margin-bottom: var(--space-4);

        .start-signing-btn {
            margin-left: auto;
            background-color: var(--brand-color);
            color: var(--background-color);
            padding: var(--space) var(--space-4);
            width: auto;

            &:hover,
            &:focus {
                background-color: var(--brand-color);
            }

            &.Mui-disabled {
                color: var(--button-color-disabled);
                background-color: var(--button-background-color-disabled);
            }
        }
    }
}
