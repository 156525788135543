@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.MuiTooltip-popper.signing-field-tooltip-popper {
    .MuiTooltip-tooltip.signing-field-tooltip {
        font-family: 'Montserrat', sans-serif;
        background-color: var(--background-color-light);
        color: var(--signing-field-text);
        font-size: var(--font-size-p3);
        padding: 6px var(--space);
        font-weight: var(--font-weight-b3);
        box-shadow: 0 0 2px 2px var(--box-shadow-color);
        display: flex;
        align-items: center;

        .warning-icon {
            font-size: var(--font-size-p1);
            color: var(--danger-color);
            margin-right: var(--space);
            font-weight: var(--font-weight-b2);
        }
    }

    .tooltip-title-container {
        display: flex;
        align-items: center;
    }

    .signing-field-tooltip-content-wrapper {
        width: 100%;
    }
}
