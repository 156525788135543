@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.login-buttons {
    margin: var(--space-2) 0;

    .continue-buttons-container {
        display: flex;
    }

    .login-buttons-container {
        display: none;
    }

    .login-button-container,
    .continue-button-container,
    .trial-button-container {
        width: 50%;
    }

    .login-button-container,
    .continue-button-container {
        padding-right: var(--space);
    }

    .trial-button-container {
        padding-left: var(--space);
    }

    &.is-visible {
        .login-buttons-container {
            display: flex;
        }
    }
}
