@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.section-with-title {

    border-radius: var(--section-border-radius, var(--space));
    background-color: var(--page-background-color);
    box-shadow: 0 1px 2px var(--box-shadow-color);
    margin: var(--space-2) 0;
    display: flex;
    flex-direction: column;
    font-size: var(--font-size-p2);
    color: var(--brand-color);

    p {
        margin: 0;
    }

    .section-title-container {
        padding: var(--space-2) var(--space-3);
        display: flex;
        align-items: center;
        color: var(--brand-color);

        .section-title {
            font-size: var(--font-size-h4);
            font-weight: var(--font-weight-b3);
            margin: 0;
        }

        strong {
            font-weight: var(--font-weight-b3);
        }

        .icon {
            color: var(--brand-color);
            margin-right: var(--space-2);
            width: 24px;
            height: 24px;
        }
    }

    .section-content-container {
        padding: var(--space-2) var(--space-3);
        border-top: 2px solid var(--border-color);
        color: var(--brand-color);
        font-size: 14px;
        font-weight: 400;

        p {
            font-size: 14px;
        }
    }
}