@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;


.colors-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .color-item {
        border: 2px solid transparent;
        border-radius: 50%;
        transition: border-color 0.2s ease;
        padding: 2px;
        width: 30px;
        height: 30px;
        cursor: pointer;
    
        &.selected {
            border: 2px solid var(--brand-color);
        }

        .color {
            border-radius: 50%;
            width: 100%;
            height: 100%;
        }
    }
}
