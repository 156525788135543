@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.transaction-summary-page {
    padding: 0;
    max-width: 800px;
    width: 100%;
    margin: 0 auto;

    .section-with-title .section-content-container,
    .section-with-title .section-content-container .icon,
    .underlined-button {
        color: var(--brand-color);
    }

    .app-button {
        margin: var(--space-2) 0 0 auto;
        display: flex;
    }

    .no-section-actions-container {
        display: flex;
        flex-wrap: wrap;
        margin-top: var(--space-3);

        .app-button {
            font-size: var(--font-size-p3);
            margin: var(--space-2) var(--space-2) 0 0;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .more-actions-section {
        .section-content-container {
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .next-to-sign-btn {
                font-size: var(--font-size-p3);
                margin: 0;
            }

            .continue-btn {
                font-size: var(--font-size-p3);
                margin: 0 0 0 auto;
            }
        }
    }

    .section-with-title {
        .section-title-container {
            .icon {
                color: var(--brand-color);
            }
        }

        &.documents-section {
            .section-title-container {
                .icon {
                    fill: var(--brand-color);
                }
            }
        }
    }

    .section-with-title.documents-section,
    .section-with-title.attachments-section {
        .section-content-container {
            padding-top: 0;
        }
    }

    .sui-document-container:first-child,
    .sui-attachment-container:first-child {
        border-top: none;
    }

    .sui-document-container:last-child {
        border-bottom: 1px solid var(--border-color);
    }

    .documents-title,
    .uploads-title {
        display: flex;
    }
}
