@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.login-news {
    display: none;
    flex-direction: column;
    flex-grow: 1;
    background-color: var(--background-color);
    height: 100vh;
    position: fixed;
    right: 0;

    .news-steps-container {
        height: 100%;
        position: relative;

        .news-steps {
            height: 100%;

            .react-swipeable-view-container {
                height: 100%;
            }
        }

        .back-arrow-container,
        .next-arrow-container {
            position: absolute;
            top: 0;
            bottom: 0;
            height: 100vh;
            max-width: 40px;
            padding: 0 4px;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 100;
            cursor: pointer;

            .back-arrow,
            .next-arrow {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            .back-arrow-icon,
            .next-arrow-icon {
                background-color: var(--background-color-light);
                padding: var(--space);
                width: 32px;
                height: 32px;
                color: var(--brand-color);
                border-radius: 50%;
            }
        }

        .back-arrow-container {
            left: 0;

            .back-arrow {
                left: 0;
                visibility: hidden;
            }

            &:hover,
            &:focus {
                .back-arrow {
                    visibility: visible;
                    background-color: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
                }
            }
        }

        .next-arrow-container {
            right: 0;

            .next-arrow {
                right: 0;
                visibility: hidden;
            }

            &:hover,
            &:focus {
                .next-arrow {
                    visibility: visible;
                    background-color: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%);
                }
            }
        }
    }

    @media (min-width: @screen-sm) {
        display: flex;
    }
}
