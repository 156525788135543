@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.signature-modal {
    .MuiDialog-paper {
        width: 90vw;
        max-width: 1900px;
        height: 90vh;
        max-height: 1500px;

        .content {
            flex-grow: 1;
        }

        &.MuiDialog-paperScrollBody {
            margin: 0;
            max-width: 100%;
            width: 100%;
        }
    }

    .line-overlay {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        .signature-line {
            position: absolute;
            height: 1px;
            margin: 0 var(--space-10);
            background-color: #000;
        }
    }

    .signature-page {
        display: block;
        width: 100%;
        height: 100%;

        .line-overlay {
            user-select: none;
            pointer-events: none;
        }
    }

    .preview-page {
        display: none;
    }

    .signature-canvas-container {
        position: relative;
        border: 1px solid var(--border-color);
        height: 100%;

        .reactEasyCrop_Container {
            background-color: var(--background-color-light);
        }
    }

    .signature-canvas {
        display: block;
        touch-action: none;
    }

    .rgb-control-container {
        display: flex;
        margin: var(--space-2) 0 var(--space-2);

        .rgb-control-title {
            height: 50px;
            padding: var(--space) var(--space-4) 0 0;
            text-transform: uppercase;
            font-size: var(--font-size-h4);
            font-weight: var(--font-weight-b2);
            color: var(--brand-color);
        }

        .rgb-control-sliders-container {
            flex: 1 0 auto;

            .slider-container {
                height: 50px;
            }
        }

        .MuiSlider-root {
            color: var(--brand-color);
        }

        .MuiSlider-markLabelActive {
            color: var(--brand-color);
        }

        .MuiSlider-markLabel {
            font-weight: var(--font-weight-n);
            line-height: var(--font-size-p2);
            font-size: var(--font-size-p2);
        }
    }

    .display-canvas-container {
        margin: 0 auto var(--space-3);
        border: 1px solid var(--border-color);
        position: relative;

        .line-overlay {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    .signature-save-error-alert {
        margin-bottom: var(--space-2);
    }

    &.is-preview {
        .signature-page {
            display: none;
        }

        .preview-page {
            display: block;
        }
    }

    @media (min-width: @screen-md) {
        .MuiDialog-paper.MuiDialog-paperScrollBody {
            margin: var(--space-4);
            width: calc(100% - var(--space-4) * 2);
            max-width: 960px;
        }
    }
}
