@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.trash-btn-container {
    .trash-icon {
        width: 25px;
        height: 25px;
        color: var(--brand-color);

        &.small {
            width: 20px;
            height: 20px;
        }
    }

    .MuiButtonBase-root.MuiIconButton-edgeEnd {
        margin-right: 0;
    }

    .Mui-disabled {
        .trash-icon {
            color: var(--icon-button-color-disabled);
        }
    }
}
