@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.floating-action-button-container {
    position: fixed;
    transform: translate(-50%, -50%);
    top: 40%;
    left: 30px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: 0.2s ease-in-out left, 0.2s ease-in-out right;
    z-index: 99999;

    .floating-actions-btn {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        z-index: 1000;
        box-shadow: 0 2px 4px var(--box-shadow-color);
        background-color: var(--button-background-color);
        color: var(--button-color);
        transition: 0.3s;

        &:hover,
        &:focus {
            background-color: var(--button-background-color-hover);
        }

        .btn-icon {
            color: var(--button-color);
            width: 25px;
            height: 25px;
            transition-property: transform;
            transition-timing-function: ease-in-out;
        }
    }

    .floating-actions-list {
        position: relative;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        margin: 0;

        .floating-action-container {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            list-style-type: none;
            transition: 0.3s;
            padding: 5px;

            .floating-action-btn-container {
                width: 100%;
                height: 100%;

                .floating-action-btn {
                    width: 100%;
                    height: 100%;
                    box-shadow: 0 2px 17px -1px rgba(0, 0, 0, 0.3);
                    background-color: var(--button-background-color);
                    transition: 0.3s;

                    .icon {
                        color: var(--button-color);
                        width: 25px;
                        height: 25px;
                    }

                    &:hover,
                    &:focus {
                        background-color: var(--button-background-color-hover);
                    }

                    &.Mui-disabled {
                        background-color: var(--button-background-color-disabled);

                        .icon {
                            color: var(--icon-button-color-disabled);
                        }
                    }

                    &.is-loading {
                        .icon {
                            animation: icon-spin 1s infinite linear;
                        }
                    }
                }

                &.is-open {
                    .floating-action-btn {
                        background-color: var(--brand-color-hover);
                    }
                }
            }
        }
    }

    &.is-dragging {
        transition: none;
    }

    &.is-open {
        .floating-actions-btn {
            background-color: var(--brand-color-hover);

            .btn-icon {
                transform: rotate(360deg);
            }
        }

        &.is-left {
            .floating-actions-btn {
                .btn-icon {
                    transform: rotate(360deg);
                }
            }
        }

        &.is-right {
            .floating-actions-btn {
                .btn-icon {
                    transform: rotate(-360deg);
                }
            }
        }
    }
}

.floating-action-btn-tooltip {
    p {
        margin: 0;
    }
}
