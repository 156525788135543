@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.thank-you-page {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;

    .section-with-title {
        width: 100%;

        .app-button {
            display: block;
            margin: var(--space-3) 0 0 auto;
        }
    }
}
