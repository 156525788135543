@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.signing-condition-icon {
    background-color: var(--brand-color);
    width: var(--space-2);
    text-align: center;
    height: var(--space-2);
    box-shadow: 0 2px 4px 0 var(--box-shadow-color);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: var(--space-2);
    position: absolute;
    right: -1px;
    top: 50%;
    transform: translate(50%, -50%);
    padding: 0;
    border: none;

    &.checkbox,
    &.radio {
        right: calc((var(--space) / 2) * -1);
    }

    .icon-container {
        width: inherit;
        height: inherit;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 1px;

        svg {
            width: 100%;
            height: 100%;
            color: var(--button-color);
        }
    }

    @media (min-width: @screen-xs) {
        .icon-container {
            padding: 3px;
        }
    }
}

.signing-field-condition-popper {
    box-shadow: var(--mui-shadow);
    background-color: var(--background-color-light);
    padding: 0 var(--space);
    border-radius: calc(var(--space) / 2);

    .content {
        .divider {
            margin: var(--space) 0;
        }

        .condition-container {
            display: flex;
            align-items: center;
            color: var(--signing-field-text);
            font-size: var(--font-size-p3);
            margin: calc(calc(var(--space) / 2)) 0;

            .condition-text {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
            }

            .condition-text,
            .condition-name {
                margin: 0;
            }

            .go-to-field-btn {
                font-size: var(--font-size-p3);
                color: var(--link-color);
                padding: 0 calc(var(--space) / 2);
                text-transform: none;
                font-weight: var(--font-weight-b2);
                width: auto;
                min-width: auto;
                height: auto;

                &.disabled {
                    opacity: var(--signing-field-disabled-opacity);
                }

                &:hover {
                    color: var(--link-color-hover);
                }
            }
        }

        .lowercase-text {
            text-transform: lowercase;
        }
    }
}
