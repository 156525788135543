@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.field-loader {
    color: var(--brand-color);
    opacity: 0.35;
    border-color: transparent;

    svg {
        background: #fff;
        border-radius: 50%;
    }
}
