@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.field-wrapper-span {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: nowrap;
}
