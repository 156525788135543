@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.signing-login {
    padding: var(--space) var(--space) 0;
    display: flex;
    flex-wrap: wrap;
    max-width: 1600px;
    width: 100%;
    margin: 0 auto;

    .signer-login-section {
        margin: 0 auto;
        max-width: 800px;
        width: 100%;

        .signer-login-section-description {
            padding: 0 0 var(--space-2);
        }

        .signer-login-actions {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            margin-top: var(--space-3);
        }
    }

    @media (min-width: @screen-xs) {
        padding: var(--space-2);
    }

    @media (min-width: @screen-lg) {
        padding: var(--space-2) var(--space-4) 0;
    }
}
