@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.next-action-container {
    color: var(--brand-color);

    &.is-no-interface {
        border-radius: 50%;
        box-shadow: 0 0 10px var(--brand-color);
        position: absolute;
        bottom: calc((var(--space) * -1) - 60px);
        left: 50%;
        transition: all 250ms linear;
        background: var(--background-color-accented);
        transform: translateX(-50%);

        .no-interface-action-button {
            height: auto;
            min-height: 50px;
            border-radius: 25px;
            padding: 0 var(--space-2);
            min-width: 0;
            display: flex;
        }

        .action-icon {
            width: 25px;
            height: 25px;
            flex-shrink: 0;
        }

        .action-name {
            padding-left: var(--space);
        }

        &.is-interface-hidden {
            bottom: calc(var(--space) * -1 + 40px);
        }

        &.is-all-done {
            box-shadow: 0 0 10px var(--success-color);

            .no-interface-action-button {
                background-color: var(--success-color);
            }
        }
    }

    &.is-all-done {
        color: var(--success-color);

        .go-to-uploads-msg {
            margin: 0;
            color: var(--brand-color);
        }

        .action-button {
            background-color: var(--success-color);
            color: var(--background-color);

            &:hover,
            &:focus {
                background-color: var(--success-color);
            }

            &.go-to-uploads {
                background-color: var(--brand-color);

                &:hover,
                &:focus {
                    background-color: var(--brand-color);
                }
            }
        }

        .MuiLinearProgress-barColorPrimary {
            background-color: var(--success-color);
        }
    }

    &.has-pending-upload {
        .go-to-uploads-msg {
            margin: 0;
            color: var(--brand-color);
        }
    }

    .remaining-actions-counter.disabled {
        color: var(--button-color-disabled);

        .MuiLinearProgress-root {
            background-color: var(--button-background-color-disabled);
        }
    }

    @media (min-width: @screen-md) {
        &.is-no-interface {
            display: none;
        }
    }
}
