@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.app-outlined-button.app-button {
    border: 1px solid rgba(var(--brand-color), 0.5);
    color: var(--button-inverted-color);
    background-color: var(--button-inverted-background-color);

    &:hover,
    &:focus {
        color: var(--button-inverted-color-hover);
        border-color: var(--button-inverted-color-hover);
        background-color: var(--button-inverted-background-color);
    }

    &.Mui-disabled {
        color: var(--button-color-disabled);
        background-color: var(--button-background-color-disabled);
    }
}
