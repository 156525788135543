@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.logo {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 120px;
    height: 100%;

    svg,
    img {
        max-width: 100%;
        width: auto;
        height: auto;
        max-height: 100%;
    }

    @media (min-width: @screen-xs) {
        max-width: 200px;
    }
}
