@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.outlined-icon-button.MuiIconButton-root {
    background-color: #fff;

    &:hover {
        background-color: #fff;
    }
}
