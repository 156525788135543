@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.documents-tool {
    --zoom-tool-height: 38px;
    --zoom-icon-height: 30px;
    --zoom-tool-max-width: 200px;
    --side-containers-max-width: calc((100% - var(--zoom-tool-max-width)) / 2);

    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--background-color-light);
    box-shadow: 0 1px 2px var(--box-shadow-color);
    padding: var(--space);
    flex-wrap: wrap;

    .zoom-tool {
        display: flex;
        background-color: var(--background-color);
        padding: calc(var(--space) / 2);
        margin-top: var(--space);
        justify-content: space-between;
        height: var(--zoom-tool-height);
        border-radius: calc(var(--zoom-tool-height) / 2);
        order: 2;
        flex-grow: 1;

        .zoom-factor-percentage {
            display: flex;
            align-items: center;
            margin: 0 auto;
            font-size: var(--font-size-p1);
            font-weight: var(--font-weight-l2);
            color: var(--brand-color);
        }

        .zoom-icon {
            width: var(--zoom-icon-height);
            height: var(--zoom-icon-height);
        }

        .zoom-reset {
            margin-left: var(--space);
        }
    }

    .document-name {
        width: 100%;
        margin: 0;
        color: var(--brand-color);
        font-size: var(--font-size-h4);
        text-align: left;
        order: 1;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .documents-navigation {
        order: 3;
        margin-right: auto;
    }

    @media (min-width: @screen-sm) {
        padding: var(--space-2);

        .document-name,
        .documents-navigation {
            max-width: var(--side-containers-max-width);
            color: var(--brand-color);
            margin: 0;
        }

        .zoom-tool {
            max-width: var(--zoom-tool-max-width);
            margin-top: 0;
        }

        .documents-navigation {
            width: 100%;
        }
    }
}
