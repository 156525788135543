@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.usage-summary-report {
    .page-title-container {
        display: flex;
        flex-wrap: wrap;

        .page-title {
            display: flex;
            align-items: center;
            width: auto;
            max-width: 100%;
            flex-wrap: wrap;

            .report-name {
                color: var(--brand-color);
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
                white-space: nowrap;
                font-weight: var(--font-weight-b1);
                font-size: var(--font-size-h2);
                line-height: calc(var(--font-size-h2) + var(--space));
                margin: 0;
            }

            .sub-title {
                width: 100%;
                color: var(--label-color);
                font-size: var(--font-size-p5);
                font-weight: var(--font-weight-b1);
                text-transform: uppercase;
                line-height: var(--font-size-p5);
            }
        }
    }

    .header-panel {
        display: flex;
        flex-wrap: wrap;
        margin: var(--space-3) 0 var(--space-2);
    }

    .from-date-picker-container,
    .run-template-container,
    .to-date-picker-container {
        width: 100%;
        margin-bottom: var(--space-2);
    }

    .run-template-container {
        display: flex;

        .run-btn {
            border-radius: 2px;
            background-color: var(--button-background-color);
            color: var(--button-color);
            font-size: var(--font-size-h4);
            font-weight: var(--font-weight-n);
            padding: calc(var(--space) / 2) var(--space-5);
            width: 100%;

            &:hover,
            &:focus {
                background-color: var(--button-background-color-hover);
            }

            &.Mui-disabled {
                color: var(--button-color-disabled);
                background-color: var(--button-background-color-disabled);
            }
        }

        .download-container {
            display: flex;
            flex-shrink: 0;
            flex-grow: 0;
            align-items: center;
            padding: 0 var(--space);
            margin-left: var(--space-2);

            .download-icon {
                color: var(--brand-color);
            }
        }
    }

    .senders-summary-pagination-container {
        display: flex;
        justify-content: center;
    }

    @media (min-width: @screen-md) {
        .from-date-picker-container,
        .run-template-container,
        .to-date-picker-container {
            width: auto;
        }

        .from-date-picker-container,
        .to-date-picker-container {
            margin-right: var(--space-2);
        }

        .run-template-container {
            margin-left: var(--space-2);
        }
    }
}
