@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.upload-attachment-actions-container {
    display: flex;
    margin: var(--space-4) 0;
    padding: var(--space) var(--space-3);
    background-color: var(--page-background-color);
    box-shadow: 0 1px 2px var(--box-shadow-color);
    flex-wrap: wrap;

    .message-container {
        display: flex;
        align-items: center;
        color: var(--brand-color);
        font-size: var(--font-size-p2);
        font-weight: var(--font-weight-n);
        width: 100%;
        margin-bottom: var(--space-3);
    }

    .buttons-container {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        flex-grow: 1;

        .attachment-action-btn {
            width: 100%;

            &:not(:first-child) {
                margin-top: var(--space-2);
            }
        }
    }

    .attachment-primary-action-btn,
    .attachment-secondary-action-btn {
        background-color: var(--brand-color);
        color: var(--button-color);
        padding: var(--space) var(--space-4);
    }

    .attachment-primary-action-btn {
        background-color: var(--brand-color);
        color: var(--button-color);

        &:hover,
        &:focus {
            background-color: var(--brand-color);
        }

        &.Mui-disabled {
            color: var(--button-color-disabled);
            background-color: var(--button-background-color-disabled);
        }
    }

    .attachment-secondary-action-btn {
        border: solid 1px var(--brand-color);
        color: var(--brand-color);
        background-color: var(--button-inverted-background-color);

        &:hover,
        &:focus {
            color: var(--button-inverted-color-hover);
            border-color: var(--button-inverted-color-hover);
        }
    }

    @media (min-width: @screen-sm) {
        flex-wrap: nowrap;

        .message-container {
            width: auto;
            margin-bottom: 0;
        }

        .buttons-container {
            flex-shrink: 0;
            flex-grow: 0;
            margin-left: auto;

            .attachment-action-btn {
                width: auto;
                margin-left: var(--space-2);

                &:not(:first-child) {
                    margin-top: 0;
                }
            }
        }
    }
}
