@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.dropzone-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    margin-bottom: var(--space-2);

    .dropzone-overlay {
        position: fixed;
        top: 0;
        bottom: 0;
        height: 100vh;
        width: 100vw;
        left: 0;
        z-index: -1;
        visibility: hidden;

        .drop-border-overlay {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: 50px;
            border: 2px dashed #fff;
            border-radius: 30px;
            pointer-events: none;
        }

        &.is-dragging {
            visibility: visible;
            z-index: 10000;
            opacity: 0;
        }

        &.is-active {
            opacity: 1;
            background-color: rgba(0, 0, 0, 0.5);

            .drop-border-overlay {
                display: flex;
            }
        }

        &.is-reject,
        &.is-accept {
            opacity: 1;
        }

        .drop-overlay-message {
            color: #fff;
            font-size: 45px;
        }
    }

    .dropzone {
        display: flex;
        flex-grow: 1;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px dashed var(--brand-color);
        cursor: pointer;
        padding: var(--space);

        .upload-icon {
            width: 30px;
            height: 30px;
            margin-bottom: var(--space);
        }

        .drop-message-container,
        .drop-here-message,
        .rejected-message,
        .drop-more-msg {
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            color: var(--brand-color);
            font-size: var(--font-size-p3);
            font-weight: var(--font-weight-l2);
            line-height: var(--font-size-p3);
        }

        &:hover,
        &:focus {
            .upload-icon {
                color: var(--link-color-hover);
            }
        }
    }

    .documents-list-container {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .documents-list {
            .document-container {
                &.is-dragging {
                    background-color: var(--item-background-color-hover);
                }
            }

            .document {
                display: flex;
                align-items: center;
                padding: 0 var(--space-2);
                font-size: var(--font-size-p3);
                height: 25px;

                &.is-from-template {
                    color: var(--label-color);
                }

                .icon-container {
                    width: 20px;
                    height: 20px;
                    margin-right: var(--space-2);
                    flex-shrink: 0;
                }

                .error-icon-container {
                    margin-right: var(--space);
                    z-index: 10000;
                }

                .name-container {
                    flex-grow: 1;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                }

                .remove-container {
                    width: 20px;

                    .MuiIconButton-root {
                        width: 20px;
                        height: 20px;
                    }

                    .MuiSvgIcon-root {
                        width: 15px;
                        height: 15px;
                    }
                }

                &.has-error {
                    .name-container,
                    .error-icon-container {
                        color: var(--danger-color);
                    }
                }
            }
        }

        .drop-more-msg {
            margin: calc(var(--space) / 2);
        }
    }

    .mobile-add-btn-container {
        margin: var(--space-2) 0;
        display: flex;
        justify-content: center;
        align-items: center;

        .add-btn {
            display: flex;
            color: var(--brand-color);
            align-items: center;

            .add-icon {
                font-size: 30px;
            }

            .label {
                font-size: var(--font-size-p2);
                font-weight: var(--font-weight-l1);
                text-transform: uppercase;
                margin-left: var(--space-2);
            }
        }
    }

    @media (min-width: @screen-lg) {
        height: 100px;

        .documents-list-container {
            .documents-list {
                max-height: 50px;
            }
        }
    }
}

.document-upload-error-tooltip {
    .MuiTooltip-arrow {
        color: var(--background-color-light);

        &::before {
            border: solid 1px var(--brand-color);
        }
    }

    .MuiTooltip-tooltip {
        background-color: var(--background-color-light);
        border: solid 1px var(--brand-color);
        color: #000;
    }
}
