@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.perfect-signature-modal{
    .MuiPaper-root {
        width:100dvw;
        max-width: none;
        max-height: none;
        height: 100dvh;
        margin: 0;
        padding: 0;
        overflow: hidden;

        .content{
            height: 100%;
            padding: 0;
            .placeholder { 
                width: 80%;
            }
        }
        .actions {
            margin: 0 24px 24px 24px;
            padding: 0;
        }
    }
}

@media (min-width: @screen-lg){
    .perfect-signature-modal{
        .MuiPaper-root {
            width: 100dvw;
            max-width: none;
            max-height: none;
            height: 100dvh;
            margin: 0;
            padding: 0;
            
        }
    }
}