@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.decline-signing-panel {
    .content-title {
        color: var(--brand-color);
        font-size: var(--font-size-h3);
        margin-bottom: var(--space-3);
    }

    .content {
        color: var(--brand-color);
        font-size: var(--font-size-p1);
        margin-bottom: var(--space);
    }
}
