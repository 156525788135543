@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.documents-navigation {
    display: flex;
    justify-content: flex-end;
    width: auto;
    margin: var(--space) 0 0;

    .pages-counter {
        display: none;
        align-items: center;
        color: var(--brand-color);

        .document-navigation-page-input {
            margin: 0 var(--space);
        }
    }

    .pages-documents-navigation {
        margin-left: var(--space-2);
        display: flex;
        align-items: center;
    }

    .document-navigation-page-input-mobile-container {
        display: block;
    }

    @media (min-width: @screen-sm) {
        .pages-counter {
            display: flex;
        }

        .document-navigation-page-input-mobile-container {
            display: none;
        }
    }
}
