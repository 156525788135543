@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.uploaded-file.MuiListItem-root {
    background-color: var(--page-background-color);
    box-shadow: 0 1px 2px var(--box-shadow-color);
    margin-top: var(--space);
    padding: var(--space);
    display: flex;

    &:hover {
        background-color: var(--brand-color-hover-light);
    }

    .MuiTouchRipple-child {
        background-color: var(--brand-color);
    }

    &::before {
        top: 0;
        left: 0;
        width: 4px;
        bottom: 0;
        content: "";
        display: block;
        opacity: 0;
        z-index: 2;
        position: absolute;
        background-color: var(--brand-color);
        transition: all 225ms linear;
    }

    .uploaded-file-container {
        width: 100%;
        padding: 0 var(--space);

        .uploaded-file-overview {
            display: flex;

            .uploaded-attachment-thumbnail-container {
                display: flex;
                flex-shrink: 0;
                width: 70px;
                height: 70px;
                justify-content: center;
                align-items: center;
                background: var(--background-color-accented);
                margin-right: var(--space-2);

                .uploaded-attachment-thumbnail-placeholder {
                    width: 25px;
                    height: 25px;
                }
            }

            .uploaded-attachment-name-container {
                display: flex;
                align-items: center;
                color: var(--brand-color);
                font-weight: var(--font-weight-b3);
                font-size: var(--font-size-h4);
            }

            .uploaded-attachment-actions-container {
                display: flex;
                margin-left: auto;

                .action-btn-container {
                    display: flex;
                    align-items: center;
                    width: 48px;

                    &.preview-btn-container {
                        margin-right: var(--space);
                    }
                }
            }
        }
    }

    &.Mui-selected {
        background-color: var(--page-background-color);
        width: calc(100% + var(--space-2));
        margin-left: calc(var(--space) * -1);
        margin-right: calc(var(--space) * -1);
        box-shadow: 0 2px 10px var(--box-shadow-color);
        padding: var(--space) var(--space-2);

        &:hover {
            background-color: var(--brand-color-hover-light);
        }

        &::before {
            opacity: 1;
        }
    }
}
