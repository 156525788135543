@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.login-forms {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    background-color: var(--background-color-accented);
    text-align: left;

    .title {
        font-size: 28px;
        font-weight: var(--font-weight-n);
        margin: 0 0 var(--space-3) 0;
        color: var(--brand-color);
    }

    .login-alert {
        text-align: left;
        margin-bottom: var(--space-2);
        width: 100%;

        .MuiAlert-message {
            font-size: var(--font-size-p3);
            font-weight: var(--font-weight-l2);

            .MuiAlertTitle-root {
                font-size: var(--font-size-p2);
                font-weight: var(--font-weight-n);
            }
        }
    }

    .password-recovery-link-container {
        margin-top: var(--space-2);

        .password-recovery-link {
            text-decoration: none;
            font-weight: var(--font-weight-b2);
            font-size: var(--font-size-p3);
            line-height: var(--font-size-p3);
        }
    }
}

.login-password-form {
    position: absolute;
    margin-top: var(--space-2);
    opacity: 0;
    pointer-events: none;

    &.is-visible {
        position: static;
        opacity: 1;
        pointer-events: initial;
    }
}
