@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.app-label {
    color: var(--brand-color);
    font-weight: var(--font-weight-b1);
    text-transform: uppercase;
    font-size: var(--font-size-p5);
    top: 0;
    transform: none;
    display: flex;
    justify-content: space-between;
}
