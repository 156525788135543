@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.handover-redirect {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    padding: 2rem 0;

    .section-with-title {
        color: var(--brand-color);
    }
}

.handover-redirect-header {
    padding: 2rem 0;
}

.launch-icon {
    width: 150px !important;
    height: 150px !important;
    color: var(--brand-color);
}
