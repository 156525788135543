@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.MuiOutlinedInput-root.base-select-container {
    .base-select {
        padding: var(--outlined-input-padding);
        background-color: var(--background-color);
        color: var(--placeholder-color);
    }
}
