@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.transaction-details {
    padding: var(--space-2);

    .transaction-summary-documents-container {
        .title {
            color: var(--brand-color);
            font-size: var(--font-size-p1);
            font-weight: var(--font-weight-b2);
        }

        .transaction-summary-documents {
            display: flex;
            flex-direction: column;
            margin-top: var(--space-2);

            .transaction-summary-document {
                display: flex;
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: 10px;
                    border-bottom: 1px solid var(--border-color-accented);
                }

                .info-container {
                    display: flex;
                    flex-wrap: wrap;
                    flex-grow: 1;
                }

                .info {
                    width: calc(25% - var(--space-2));
                    margin: 0 var(--space-2) var(--space-2) 0;
                    align-items: center;
                    min-width: 200px;

                    &.completed {
                        color: var(--completed-color);
                        text-transform: uppercase;
                    }

                    &.name-container {
                        display: flex;

                        .icon-container {
                            display: flex;
                            align-items: center;
                            width: 25px;
                            margin-right: 16px;

                            svg {
                                width: 25px;
                                height: 25px;
                            }
                        }
                    }

                    &.date {
                        display: flex;

                        .date-value {
                            margin-left: var(--space);
                        }
                    }
                }
            }
        }
    }

    .transaction-summary-recipients-container {
        margin-top: var(--space-2);

        .title {
            color: var(--brand-color);
            font-size: var(--font-size-p1);
            font-weight: var(--font-weight-b2);
        }

        .transaction-summary-recipients {
            display: flex;
            flex-direction: column;
            margin-top: var(--space-2);

            .transaction-summary-recipient {
                display: flex;
                flex-wrap: wrap;
                flex-grow: 1;
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: 10px;
                    border-bottom: 1px solid var(--border-color-accented);
                }

                .info {
                    width: calc(25% - var(--space-2));
                    margin: 0 var(--space-2) var(--space-2) 0;
                    align-items: center;
                    min-width: 200px;

                    &.completed {
                        color: var(--completed-color);
                        text-transform: uppercase;
                    }

                    &.date {
                        display: flex;

                        .date-value {
                            margin-left: var(--space);
                        }
                    }
                }

                .email {
                    font-size: var(--font-size-p2);
                    line-height: var(--font-size-p2);
                }
            }
        }
    }
}
