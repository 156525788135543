@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.svg-drawer-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;

    &.portrait {
        @media screen and (max-width: @screen-sm) {
            transform: rotate(90deg);
            transform-origin: top left;
            width: 100dvh;
            height: 100dvw;
            position: absolute;
            top: 0;
            left: 100%;
        }
    }

    &.landscape {
        @media screen and (max-width: @screen-sm) {
            width: 100dvw;
            height: 100dvh;
        }
    }

    .content-wrapper {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
    }

    .svg-drawer-wrapper {
        flex: 1;
        width: 100%;
        background-color: #ffffff;
        position: relative;
 
        
        .svg-drawer {
            width: 100%;
            height: 100%;
            touch-action: none;
            z-index: 1;
            position: relative;
        }
        
        .placeholder {
            position: absolute;
            left: 50%;
            bottom: min(21%, 100px);
            width: 90%;
            transform: translate(-50%, -50%);
            text-align: center;
            z-index: 0;

            .line {
                width: 100%;
                height: 2px;
                background-color: var(--brand-color);
            }
            .text {
                margin-top: 8px;
            }
        }
    }

    .colors-container{
       position: absolute;
       left: var(--space-2);
       bottom: var(--space-2);
       z-index: 2;

    }

    .bottom-right-actions-container{
        position: absolute;
        right: var(--space-2);
        bottom: var(--space-2);
        z-index: 2;
        
        .dialog-button {
            margin-right: var(--space-2);
        }
    }
}

.perfect-signature-drawer-modal {
    .content {
        height: 100%;
        padding: var(--space) var(--space-4) var(--space-4) var(--space-4);
    }

}
