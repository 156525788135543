@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.page-renderer-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size-p3);
    color: var(--brand-color);
    padding: 0 calc(var(--space) / 2);

    .document-name {
        position: absolute;
        bottom: 0;
        left: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        box-shadow: 0 0 4px 1px var(--box-shadow-color);
        padding: 0 var(--space);
        background-color: var(--button-background-color);
        color: var(--button-color);
        max-width: 250px;
    }

    .page-info-container {
        position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        align-items: center;
        box-shadow: 0 0 4px 1px var(--box-shadow-color);
        padding: 0 var(--space);
        background-color: var(--button-background-color);
        color: var(--button-color);
    }
}
