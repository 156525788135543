@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.bottom-bar-collapsed-container {
    flex-shrink: 0;

    .bottom-bar {
        margin: var(--space) 0 0;
        background-color: var(--background-color-light);
        display: flex;
        flex-wrap: wrap;

        .next-action-container {
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;
            flex-direction: column;
            flex-grow: 1;
            padding: var(--space);

            .action-button {
                margin: var(--space) 0 0 0;

                .icon {
                    margin-right: var(--space-2);
                    font-size: var(--font-size-p2);
                }
            }
        }
    }

    @media (min-width: @screen-md) {
        .bottom-bar {
            flex-direction: row;
            margin-top: var(--space-2);

            .in-person-session-information {
                padding: var(--space-2);
            }

            .next-action-container {
                padding: var(--space-2);
                flex-direction: row;
                align-items: center;

                .action-button {
                    margin: 0 0 0 var(--space-3);
                }
            }
        }
    }
}
