@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.not-found-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1600px;
    margin: 0 auto;
    padding: var(--space-4) var(--space-2);

    .page-title {
        color: var(--label-color);
        font-size: var(--font-size-p0);
        font-weight: var(--font-weight-b3);
        text-align: center;
    }

    .message-container {
        margin-bottom: var(--space-5);
        color: var(--placeholder-color);
        text-align: center;
        font-size: var(--font-size-p1);
        font-weight: var(--font-weight-b3);

        .message {
            margin: 0;
        }
    }

    .signature-image {
        max-width: 640px;
        width: 100%;
    }

    .link-container {
        margin-bottom: var(--space-5);

        .link {
            color: var(--brand-color);
            margin: var(--space-4) 0;
            font-size: var(--font-size-h4);
            font-weight: var(--font-weight-b1);
            text-decoration: none;
        }
    }

    @media (min-width: @screen-xs) {
        .page-title {
            color: var(--label-color);
            font-size: var(--font-size-h0);
        }
    }
}
