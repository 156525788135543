@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.highlight-mixin {
    box-shadow: var(--signing-field-brand-box-shadow);

    &.required {
        box-shadow: var(--signing-field-brand-box-shadow);

        // this style applies for all approval related fields which arr required but empty or contains error
        &:not(.has-value),
        &.error  {
            box-shadow: var(--signing-field-error-box-shadow) !important;
            border: 1px solid var(--danger-color);
        }
    }
}

.signing-field-container {
    font-size: var(--font-size-p3);
    border-radius: 4px;
    position: absolute;
    transform: translateZ(0);
    will-change: auto;

    &:hover,
    &.highlight-approval-related-fields {
        .highlight-mixin();
    }

    *,
    .MuiInputBase-root,
    .MuiMenuItem-root,
    .MuiInputBase-input {
        font-family: 'Montserrat', sans-serif;
    }

    .MuiMenuItem-root,
    .MuiInputBase-root,
    .MuiInputBase-input,
    .MuiCheckbox-root,
    .MuiCheckbox-root.Mui-checked,
    .MuiRadio-root,
    .MuiRadio-root.Mui-checked {
        color: var(--signing-field-color);

        /* stylelint-disable-next-line no-descending-specificity */
        svg {
            color: var(--signing-field-color);
        }
    }

    &.is-condition-makes-active-field-disabled {
        border: 1px solid var(--danger-color);
        box-shadow: var(--signing-field-error-box-shadow);
    }

    &.is-hovering-field-from-condition {
        outline: 2px solid var(--brand-color-hover);
        box-shadow: var(--mui-shadow);
        padding: 1px;

        &.checkbox,
        &.radio {
            outline: none;
            border: 1px solid transparent;

            .MuiIconButton-label {
                outline: 2px solid var(--brand-color-hover);
                box-shadow: var(--mui-shadow);
                padding: 0;
            }
        }
    }

    &:focus,
    &:focus-within {
        outline: 2px solid var(--brand-color-hover);
        opacity: 1;

        &:hover {
            outline: none;
        }
    }
}
