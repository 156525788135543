@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.base-button {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;
    background: transparent;
    font: inherit;
    line-height: normal;
    outline: none;
    text-align: inherit;
    border-radius: 0;
    cursor: pointer;
    color: var(--brand-color);

    &.disabled {
        color: var(--button-color-disabled);

        &:hover {
            color: var(--button-color-disabled);
        }
    }

    &:hover {
        color: var(--brand-color-hover);
    }
}
