@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.reassign-signing-panel {
    .content-title {
        color: var(--brand-color);
        font-size: var(--font-size-h3);
        margin-bottom: var(--space-3);
    }

    .form-instruction {
        color: var(--brand-color);
        margin-bottom: var(--space-3);
    }

    .field-text {
        color: #2f507b;
        font-size: var(--font-size-h4);
        font-weight: var(--font-weight-n);
    }

    .subtitle {
        margin: var(--space-3) 0;
        font-size: var(--font-size-h4);
        font-weight: var(--font-weight-b2);
    }

    .content {
        color: var(--brand-color);
        font-size: var(--font-size-p1);
        margin-bottom: var(--space);
    }

    .auth-type-radio-group {
        margin-bottom: var(--space-3);
    }

    .side-panel-btn-container {
        justify-content: end;
    }
}
