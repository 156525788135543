@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.reassign-auth-sms-container {
    .sms-container {
        position: relative;
        color: var(--brand-color);

        &.prefilled {
            background-color: var(--prefilled-background-color);
        }

        .MuiInputBase-root {
            padding-left: 54px;
        }

        .base-select-container {
            width: 54px;
        }

        .base-input-container {
            width: 100%;
        }

        .MuiSelect-select {
            width: 30px;
            min-width: auto;
            font-size: 0;
            opacity: 0;
        }

        >.MuiInputBase-root {
            padding-left: 0;
            z-index: 1;

            &::before,
            &::after {
                content: none;
            }

            &.MuiInputBase-root {
                padding-left: 0;
            }
        }

        >svg,
        >img,
        >.MuiInputBase-root {
            position: absolute;
            top: 50%;
            left: 10px;
            transform: translateY(-50%);
            z-index: 1;
        }

        >svg,
        >img {
            width: 30px;
            height: auto;
        }

        .flag-wrap {
            width: 30px;
            height: 30px;

            svg {
                width: 100%;
                height: auto;
            }
        }

        .PhoneInputCountryIcon {
            width: auto;
            height: auto;
            box-shadow: none;
            background-color: transparent;
        }

        &.outlined {
            >svg,
            >img,
            >.MuiInputBase-root {
                left: 10px;
            }
        }
    }
}
