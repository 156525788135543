@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.bullets-container {
    margin: var(--space-2) 0 var(--space-4);

    .bullets {
        display: flex;
        justify-content: center;
    }
}
