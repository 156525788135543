@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.side-panel {

    &>.MuiPaper-root {
        width: 100%;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        background-color: var(--sidepanel-bg);
    }

    .side-panel-container {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 100vh;
    }

    .side-panel-simple-bar {
        min-height: 0;
        max-height: 100%;
        z-index: 1;
    }

    .form-wrapper {
        display: flex;
        flex-direction: column;
        height: 100%;
        overflow: hidden;
    }

    .MuiAlert-root {
        margin-bottom: var(--space-2);
    }

    // .MuiInput-root {
    // &::before {
    // border-bottom: solid 0.5px var(--border-color);
    // }
    // }

    .side-panel-header {
        margin: var(--space-3) var(--space-4) var(--space) var(--space-4);
    }

    .side-panel-content {
        margin: 0 var(--space-4) 0 var(--space-4);
    }

    .loading {
        position: absolute;
        justify-content: flex-end;

        .spinner {
            margin-right: calc((100% - 58px) / 2);
        }
    }

    .title-container {
        display: flex;
        align-items: center;
        margin: 0 0 var(--space-2) 0;

        .title {
            color: var(--brand-color);
            font-size: var(--font-size-h2);
            font-weight: var(--font-weight-b1);
            margin: 0 var(--space-2) 0 0;
            word-break: break-all;
        }

        .subtitle {
            color: var(--brand-color);
            font-size: var(--font-size-p2);
            font-weight: var(--font-weight-n);
            margin: var(--space) 0 0;
        }

        .close-icon-container {
            margin: -2px -2px -2px auto;
            padding: 2px;
            background-color: var(--background-color);
            color: var(--brand-color);
        }
    }

    .field-container,
    .app-input-container,
    .app-select-container {
        margin: 0 0 var(--space-2) 0;
    }

    .switch-container {
        width: 100%;
        margin-left: 0;
        border-bottom: solid 0.5px var(--border-color);
        padding: var(--space) 0;
    }

    .side-panel-btn-container {
        display: flex;
        justify-content: space-between;
        margin-top: auto;
        padding: var(--space-3) var(--space-4);
        background-color: var(--footer-background-color);

        .btn {
            flex: 1;

            &.secondary-btn {
                background-color: var(--button-cancel-background-color);
                color: var(--button-color);
                margin-right: var(--space-2);
            }

            &.primary-btn {
                background-color: var(--button-background-color);
                color: var(--button-color);

                &:hover,
                &:focus {
                    background-color: var(--button-background-color-hover);
                }

                &.Mui-disabled {
                    color: var(--button-color-disabled);
                    background-color: var(--button-background-color-disabled);
                }
            }
        }
    }

    @media (min-width: (@sidepanel-width + 200px)) {
        &>.MuiPaper-root {
            width: var(--sidepanel-width);
        }

        .MuiAlert-root {
            width: 100%;
        }
    }
}