@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.sms-login {
    .re-send-btn {
        margin-top: var(--space-2);
        width: 100%;
        order: 2;
        font-size: var(--font-size-p3);
        height: auto;
    }

    .authenticate-btn {
        width: 100%;
        order: 1;
    }

    @media (min-width: @screen-sm) {
        .re-send-btn {
            width: auto;
            margin: 0 auto 0 0;
            order: 1;
            font-size: var(--font-size-p2);
        }

        .authenticate-btn {
            width: auto;
            order: 2;
        }
    }
}
