@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.bullet {
    width: 10px;
    height: 10px;
    border: solid 1px var(--brand-color);
    background-color: rgba(255, 255, 255, 0);
    margin: 2px;
    border-radius: 50%;
    cursor: pointer;

    &.is-active {
        background-color: var(--brand-color);
    }
}
