@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.registration {
    position: relative;

    .loading {
        position: absolute;
    }
}

.registration-form {
    margin-bottom: var(--space-2);

    .change-password {
        .body {
            margin-top: 0;
        }

        .title {
            display: none;
        }

        .change-password-row {
            border-bottom: none;

            .label {
                color: var(--brand-color);
                font-size: var(--font-size-p2);
                font-weight: var(--font-weight-n);
            }

            .input-container {
                width: 100%;
                margin: 0;

                .label {
                    font-size: var(--font-size-p3);
                }
            }

            .new-password {
                order: 0;
            }

            .password-strangth-container {
                order: 1;
            }

            .confirm-password {
                order: 2;
            }

            &.current-password {
                display: none;
            }
        }
    }

    .app-input-container {
        margin-top: var(--space-2);
    }

    .terms-and-conditions {
        display: flex;
        align-items: center;
        margin: var(--space) 0;
        color: var(--brand-color);
        font-size: var(--font-size-p2);
        font-weight: var(--font-weight-n);

        .link-container {
            .link {
                text-decoration: none;
                font-weight: var(--font-weight-b2);
            }
        }
    }

    .save-btn {
        margin-top: var(--space);
        border-radius: 8px;
        background: var(--button-background-color);
        font-size: var(--font-size-h4);
        font-weight: var(--font-weight-b2);
        text-transform: none;
    }
}
