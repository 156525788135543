@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.login-page {
    --form-width-lg: 600px;
    --form-width-sm: 450px;

    width: 100%;
    margin: 0 auto;
    display: flex;
    min-height: 100vh;

    .logo-container {
        margin-bottom: var(--space-2);

        .logo {
            max-width: 300px;
            max-height: 200px;
            padding: 0;
            justify-content: flex-start;
        }

        .default-logo-img {
            width: 100%;
        }
    }

    .login-form-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        background-color: var(--background-color-accented);
        padding: var(--space-3) var(--space-5);
        position: relative;

        .loading {
            position: absolute;
        }
    }

    .support-container {
        margin-top: auto;
        padding-top: var(--space-3);
        color: var(--brand-color);
        font-size: var(--font-size-p2);
        font-weight: var(--font-weight-n);

        .support-title {
            color: var(--label-color);
            font-size: var(--font-size-p3);
            font-weight: var(--font-weight-b2);
        }

        a {
            text-decoration: none;
        }
    }

    .language-selector {
        margin: var(--space-3) 0 0;
        width: auto;
    }

    .divider {
        margin: var(--space) 0;
        width: 100%;
    }

    @media (min-width: @screen-sm) {
        .login-form-container {
            width: var(--form-width-sm);
        }

        .login-news {
            width: calc(100% - var(--form-width-sm));
        }
    }

    @media (min-width: @screen-lg) {
        .login-form-container {
            width: var(--form-width-lg);
            padding: var(--space-6) var(--space-10);
        }

        .login-news {
            width: calc(100% - var(--form-width-lg));
        }
    }
}
