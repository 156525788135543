@screen-xs: 480px;
@screen-sm: 780px;
@screen-md: 992px;
@screen-lg: 1220px;
@sidepanel-width: 420px;

.login-notifications {
    width: 100%;
    margin-bottom: var(--space-2);

    .notification-alert {
        width: 100%;
        margin-bottom: var(--space-2);
        padding: 0;
        border-top: none;
        border-left: none;
        border-right: none;
        border-radius: 0;
        text-align: left;

        .MuiAlert-message {
            font-size: var(--font-size-p3);
            font-weight: var(--font-weight-l2);

            .MuiAlertTitle-root {
                font-size: var(--font-size-p2);
                font-weight: var(--font-weight-n);
            }
        }
    }
}
